import './Header.css'
import logo from '../../assets/images/لوغو شركة البريدي.png'
import { ButtonPrimary } from '../../components/index';
import { useTranslation } from 'react-i18next';


function Header() {
    const { t, i18n } = useTranslation();
  
    function menuToggle() {
      let toggleBtn = document.querySelector('.navbar-toggler');
      toggleBtn.click();
    }

    const switchDirction = () => {
      const htmlElement = document.getElementById('htmlElement')
      if(htmlElement.getAttribute('dir') === 'ltr') {
        htmlElement.setAttribute('dir', 'rtl')
      } else if(htmlElement.getAttribute('dir') === 'rtl') {
        htmlElement.setAttribute('dir', 'ltr')
      }
    }
  
    const changeLng = (ln) => {
      i18n.changeLanguage(ln);
      switchDirction();
    }
    return (
    <>
    <nav className="container navbar navbar-expand-lg fixed-top rounded">
    <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center" href="#home-page">
            <img className='logo' src={logo} alt=''/>
            <div className='logo-info'>
            <p>{t('company-name')}</p>
            <span>{t('company-disc')}</span>
            </div>
        </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#home-page" onClick={(menuToggle)}>{t('headerLi0')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about-us" onClick={(menuToggle)}>{t('headerLi1')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#why-us" onClick={(menuToggle)}>{t('headerLi2')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href='#our-serv' onClick={(menuToggle)}>{t('headerLi3')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href='#contact-us' onClick={(menuToggle)}>{t('headerLi4')}</a>
            </li>
          </ul>
          <select className="languagSelector" onChange={(e) => changeLng(e.target.value)}>
                <option className='langOption' value="ar">AR</option>
                <option className='langOption' value="en">EN</option>
          </select>
        </div>
          <ButtonPrimary hide='hide-button' href='#footer'>
            {t('askYourConsult')}
          </ButtonPrimary>
  </div>
</nav>
    </>
  )
}

export default Header