import { useTranslation } from 'react-i18next';
import { CardNoBorder } from '../../components';
import { BsFileEarmarkRuled, BsFileEarmarkPersonFill } from "react-icons/bs";
import { PiHandshake } from "react-icons/pi";
import { LuMedal } from "react-icons/lu";
import { IoPersonAddOutline } from "react-icons/io5";


const WorksData = () => {
    const { t, i18n } = useTranslation();

    let WorksDataArr = [
        {id: '1', icon: <BsFileEarmarkRuled />     , title: t('ourWorkTitle1'), info: t('ourWorkInfo1')},
        {id: '2', icon: <IoPersonAddOutline />     , title: t('ourWorkTitle2'), info: t('ourWorkInfo2')},
        {id: '3', icon: <PiHandshake />            , title: t('ourWorkTitle3'), info: t('ourWorkInfo3')},
        {id: '4', icon: <LuMedal />                , title: t('ourWorkTitle4'), info: t('ourWorkInfo4')}
    ]

    let worksCards = WorksDataArr.map(card => {
        return <CardNoBorder key={card.id} icon={card.icon} title={card.title} info={card.info} />
      })

    return (
        <>
        {worksCards}
        </>
    )
}



export default WorksData;