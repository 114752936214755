import { useTranslation } from 'react-i18next';
import { Cards } from '../../components';
import { CiMobile1, CiLocationOn } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";



const ConatctUsData = () => {
    const { t, i18n } = useTranslation();
    
    let contactUsDataArr = [
        {id: '1', icon: <CiMobile1 />, title: t('contactUsTitle1')           , info: t('contactUsInfo1') , text: t('contactUsText1')},
        {id: '2', icon: <HiOutlineMail />, title: t('contactUsTitle2'),        info: t('contactUsInfo2') , text: t('contactUsText2')},
        {id: '3', icon: <CiLocationOn />, title: t('contactUsTitle3')        , info: t('contactUsInfo3') , text: t('contactUsText3')}
    ]

    let contactCard = contactUsDataArr.map(card => {
        return <Cards key={card.id} icon={card.icon} title={card.title} info={card.info} text={card.text}/>
      })
    
    return (
        <>
        {contactCard}
        </>
    )
}

export default ConatctUsData;