import { Card } from "../../components/index";
import { useTranslation } from 'react-i18next';
import { IoPersonOutline } from "react-icons/io5";
import { GiPipeOrgan } from "react-icons/gi";
import { BsBuildings } from "react-icons/bs";


const ServicesData = () => {
     const { t, i18n } = useTranslation();
     
     let ServicesDataArr = [
        {id: '1', icon:<IoPersonOutline />   , title: t('serviceTitle1'), info: t('serviceInfo1') },
        {id: '2', icon:<BsBuildings /> , title: t('serviceTitle2'), info: t('serviceInfo2') },
        {id: '3', icon:<GiPipeOrgan />, title: t('serviceTitle3'), info: t('serviceInfo3') }
    ]

    let cards = ServicesDataArr.map(card => {
        return <Card key={card.id} icon={card.icon} title={card.title} info={card.info} />
      })
    
    return (
        <>
        {cards}
        </>
    )
}


export default ServicesData;