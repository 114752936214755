import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend";

import ar from "./locals/ar/translation.json";
import en from "./locals/en/translation.json";


const resources = {
  ar: {translation: ar },
  en: {translation:  en }
  };

i18n
  .use(LanguageDetector)
  .use(initReactI18next) 
  .use(HttpApi)
  .init({
    resources,
    fallbackLng: "ar",
    detection: {
        order: ['path', 'cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator'],
        caches: ["cookie"]
    },
    interpolation: {
      escapeValue: false
    },
    react: {
        useSuspense: false
    },
    backend: {
        loadPath: "locale/{{lng}}/translation.json" 
    }
  });

  export default i18n;