import './Homepage.css'
import { AiOutlineTeam, AiOutlineSafety  } from "react-icons/ai";
import { PiHandshake } from "react-icons/pi";
import { ButtonPrimary, SectionHeader, SectionWrap, ButtonSecoundry, ButtonTransparent, Card, CardNoBorder, Cards } from '../../components/index';
import ServicesData from '../../assets/data/servicesData';
import ConatctUsData from '../../assets/data/ContactUsData';
import WorksData from '../../assets/data/WorksData';
import logo from '../../assets/images/لوغو شركة البريدي.png';
import image_9 from '../../assets/images/tingey-injury-law-firm-DZpc4UY8ZtY-unsplash.jpg';
import image_1 from '../../assets/images/dylan-gillis-KdeqA3aTnBY-unsplash.jpg';
import { useTranslation } from 'react-i18next';

import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init();

function Homepage() {
  const { t, i18n } = useTranslation();
  
  
 
  
  return (
    <>
    {/* start homepage */}
      <div id='home-page' className='hompage-background'>
        <img src={logo} alt=''/>
        <h1>{t('company-name')}</h1>
        <p>{t('company-disc')}</p>
        <p className='slogen'>{t("slogan")}</p>
        <div className='buttons'>
        <ButtonPrimary hide='' href='#footer'>
          {t('askYourConsult')}
        </ButtonPrimary>
        <ButtonSecoundry href='#our-serv'>
          {t('company-services')}
        </ButtonSecoundry>
        </div>
      </div>
    {/* End homepage */}

  {/* start about us */}
  <div id='about-us'></div>
    <SectionWrap>
      <SectionHeader title={t('summray')} disc={t('summraySlogen')}/>
          <div className='about-info' data-aos='fade-up'>
            <div className='about-item'>
              <p>{t('welcomTo')} </p>
              <h4 className='primary-color mb-3'>{t('summaruH4')}</h4>
              <p>{t('summary-disc')}</p>
              <h4 className='primary-color mb-3'>{t('ourMesssage')}</h4>
              <p className='mb-5'>{t('massegCont')}</p>
              <div className='about-button'>
              <ButtonPrimary href='#our-serv'>
                {t('company-services')}
              </ButtonPrimary>
              <ButtonSecoundry href='#contact-us'>
                {t('contactUsBtn')}
              </ButtonSecoundry>  
              </div>
            </div>
            <div className='about-img'>
              <img className='w-100' src={image_1} alt=''/>
            </div>
          </div>
    </SectionWrap>
    {/* End about us */}

    {/* start why us section */}
    <div className='overlay'>
    <div className='why-us-section' data-aos='fade-up'>
    <SectionWrap>
      <SectionHeader title={t('whyUsSmall')} disc=''/>
      <p className='mb-5'>{t('whyContent')}</p>
      <ButtonTransparent href='#contact-us'>
        {t('contactUsBtn')}
      </ButtonTransparent>
    </SectionWrap>
    </div>
    </div>
    {/* end why us section */}
    
    {/* start why us2 section */}
    <div id='why-us'></div>
    <SectionWrap>
      <div className='why-us2' data-aos='fade-up'>
        <div className='why-us2-disc'>
          <SectionHeader title={t('whyUs2Title')} disc={t('whyUs2Diec')}/>
          <div className='disc-item'>
            <div>
            <AiOutlineTeam />
            </div>
            <h3>{t('whyUs2H31')}</h3>
            <p>{t('whyUs2P1')}</p>
          </div>
          <div className='disc-item'>
            <div>
            <PiHandshake />
            </div>
            <h3>{t('whyUs2H32')}</h3>
            <p>{t('whyUs2P2')}</p>
          </div>
          <div className='disc-item'>
            <div>
            <AiOutlineSafety />
            </div>
            <h3>{t('whyUs2H33')}</h3>
            <p>{t('whyUs2P3')}</p>
          </div>
        </div>
        <img className='w-100' src={image_9} alt=''/>
      </div>
    </SectionWrap>
    {/* end why us2 section */}

    {/* start our services sectio */}
    <div id='our-serv' className='our-services' data-aos='fade-up'>
      <SectionWrap>
        <SectionHeader title={t('ourServisesTitle')} disc={t('ourServicesDisc')}/>
        <div className='card-holder'>
          <ServicesData/>
        </div>
      </SectionWrap>
    </div>
    {/* end our services sectio */}
   
    {/* Start Contact Us section */}
    <div id='contact-us' data-aos='fade-up'>
    <SectionWrap>
      <SectionHeader title={t('contactUsTitle')} disc={t('contactUsDisc')}/>
      <div className='card-holder'>
        <ConatctUsData/>
        </div>
    </SectionWrap>
    </div>
    {/* End Contact Us section */}

    {/* start our works section */}
    <div className='our-works' data-aos='fade-up'>
    <SectionWrap>
      <SectionHeader title={t('ourWorkTitle')} disc={t('ourWorkDisc')} />
      <div className='card-holder'>
        <WorksData/>
      </div>
    </SectionWrap>
    </div>
    {/* end our works section */}
    </>
  )
}

export default Homepage